import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export interface ReactResponsiveInitialProps {
  settings: Parameters<typeof useMediaQuery>[0];
  device?: Parameters<typeof useMediaQuery>[1];
  onChange?: Parameters<typeof useMediaQuery>[2];
}

export function useMatchMediaQuery(
  settings: ReactResponsiveInitialProps['settings'],
  device?: ReactResponsiveInitialProps['device'],
  onChange?: ReactResponsiveInitialProps['onChange']
) {
  const [isMatched, setIsMatched] = useState<boolean | undefined>(undefined);
  const isMediaQueryMatched = useMediaQuery(settings, device, onChange);

  useEffect(() => {
    setIsMatched(isMediaQueryMatched);
  }, [isMediaQueryMatched]);

  return isMatched;
}
