import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '../theme';
import { MOBILE_WIDTH } from '../breakpoints';
import { getColorWithAlpha, useMatchMediaQuery } from '../utils';

export type CalloutProps = PropsWithChildren<{
  icon?: ReactNode;
  backgroundColor: ThemeColor;
  button?: ReactNode;
  direction?: 'row' | 'column';
}>;

export const Callout = ({
  children,
  icon,
  backgroundColor,
  button,
  direction,
  ...forwardingProps
}: CalloutProps) => {
  const isMobileQuery = useMatchMediaQuery({ maxWidth: MOBILE_WIDTH });
  const finalDirection = direction ?? (isMobileQuery ? 'column' : 'row');

  return (
    <Container
      _backgroundColor={backgroundColor}
      _direction={finalDirection}
      {...forwardingProps}
    >
      {icon}

      <Content>{children}</Content>

      {button && <ButtonContainer>{button}</ButtonContainer>}
    </Container>
  );
};

const Content = styled.div`
  ${({ theme }) => theme.font.text}
  flex-basis: 100%;
`;

const Container = styled.div<{
  _backgroundColor: ThemeColor;
  _direction: 'row' | 'column';
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: ${({ theme, _backgroundColor }) =>
    getColorWithAlpha(theme.colors[_backgroundColor], 0.1)};
  border-radius: 4px;
  padding: 12px 12px;

  ${({ _direction }) => {
    const textAlign = {
      row: 'left',
      column: 'center'
    }[_direction];

    return css`
      flex-direction: ${_direction};

      ${Content} {
        text-align: ${textAlign};
      }
    `;
  }}
`;

const ButtonContainer = styled.div``;
