import { useOccupationsQuery, useProfileQuery } from '@api';

export const useShowProFeaturesPage = () => {
  const { data: profileData } = useProfileQuery();
  const { data: dataOccupations } = useOccupationsQuery();

  if (!profileData || !dataOccupations) {
    return undefined;
  }

  const currentOccupation = dataOccupations.payload.find(
    ({ pid }) => pid === profileData.occupationPid
  );

  const isOccupationSuitable = Boolean(
    currentOccupation &&
      ['freelancer', 'agency'].includes(currentOccupation.alias)
  );

  return isOccupationSuitable;
};
