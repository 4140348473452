import styled, { css } from 'styled-components';
import { AngleBoldLeft20, AngleBoldRight20 } from '@elfsight/icons';
import { Tooltip, useMatchMediaQuery } from '@elfsight-universe/ui-common';

export interface RegularEnterpriseArrowSwitchProps {
  isEnterprise: boolean;
  onSwitchRequest: (toEnterprise: boolean) => void;
}

export function RegularEnterpriseArrowSwitch({
  isEnterprise,
  onSwitchRequest
}: RegularEnterpriseArrowSwitchProps) {
  const direction: Direction = isEnterprise ? 'left' : 'right';
  const isCompact = useMatchMediaQuery({ maxWidth: 1240 });
  const shouldDisplayCaption = !isCompact;
  const shouldDisplayTooltip = isCompact;
  const captionText = isEnterprise
    ? 'Need a cheaper plan?'
    : 'Plans with higher limits are available';
  const arrowElement = (
    <Arrow>
      {direction === 'left' && <AngleBoldLeft20 />}
      {direction === 'right' && <AngleBoldRight20 />}
    </Arrow>
  );

  function handleClick() {
    onSwitchRequest(!isEnterprise);
  }
  return (
    <Container
      $direction={direction}
      $compact={!!isCompact}
      onClick={handleClick}
    >
      {shouldDisplayTooltip ? (
        <Tooltip
          key={direction}
          content={captionText}
          placement={direction === 'left' ? 'right' : 'left'}
        >
          {arrowElement}
        </Tooltip>
      ) : (
        arrowElement
      )}
      {shouldDisplayCaption && <Caption>{captionText}</Caption>}
    </Container>
  );
}

type Direction = 'left' | 'right';

const Container = styled.button<{ $direction: Direction; $compact: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 50%;
  width: ${({ $compact }) => ($compact ? '48px' : '100px')};
  padding: 0;
  border: none;
  outline: none;
  background: none;
  transform: translateY(-50%);
  cursor: pointer;

  ${({ $direction, $compact }) =>
    $direction === 'right' &&
    css`
      left: ${$compact ? 'calc(100% + -24px)' : 'calc(100% + 8px)'};
    `}

  ${({ $direction, $compact }) =>
    $direction === 'left' &&
    css`
      right: ${$compact ? 'calc(100% + -24px)' : 'calc(100% + 8px)'};
    `}
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  ${Container}:hover & {
    filter: brightness(0.95);
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const Caption = styled.div`
  margin-top: 8px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.gray50};
`;
