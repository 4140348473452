import { MouseEvent } from 'react';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Button,
  Form,
  H2,
  Icon,
  Input,
  Modal,
  ModalProps,
  P,
  useAutoFocus,
  useMatchMediaQuery
} from '@elfsight-universe/ui-common';
import { InviteAccountMemberRequest } from '@elfsight-universe/service-core-contracts/iam';
import { useInviteAccountMemberMutation } from '@api';
import { catchUnhandledError } from '@modules/_error';
import { ToastInviteCollaborator } from '@modules/collaborators-modals/components/toast-invite-collaborator';
import { ToastInviteAlreadyExistCollaborator } from '@modules/collaborators-modals/components/toast-invite-already-exist-collaborator';
import InviteIcon from './assets/modal-invite-collaborator.svg';

export type InviteCollaboratorModalProps = Omit<
  ModalProps,
  'title' | 'actions'
> & {
  onSuccess: () => void;
  withLinkToMembers?: boolean;
};

const XS_MOBILE_WIDTH = 440;

export function InviteCollaboratorModal({
  isOpen,
  withLinkToMembers,
  onRequestClose,
  onSuccess,
  ...forwardingProps
}: InviteCollaboratorModalProps) {
  const isSmallMobile = useMatchMediaQuery({
    maxWidth: XS_MOBILE_WIDTH
  });

  const { mutate: inviteAccountMember } = useInviteAccountMemberMutation({
    onSuccess: (_, { email }) => {
      toast((t) => (
        <ToastInviteCollaborator
          email={email}
          onClose={() => toast.dismiss(t.id)}
          withLink={withLinkToMembers}
        />
      ));
      resetFormValues();
      onSuccess();
    },
    onError: (error, { email }) => {
      if (error === 'ACCOUNT_MEMBER_ALREADY_EXIST') {
        toast((t) => (
          <ToastInviteAlreadyExistCollaborator
            email={email}
            onClose={() => toast.dismiss(t.id)}
            withLink={withLinkToMembers}
          />
        ));
        resetFormValues();
      }

      catchUnhandledError(error, ['ACCOUNT_MEMBER_ALREADY_EXIST']);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset: resetFormValues,
    clearErrors
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: classValidatorResolver(InviteAccountMemberRequest),
    defaultValues: {
      email: ''
    }
  });

  const registerEmail = register('email', {
    onChange: () => {
      clearErrors('email');
    }
  });
  const autoFocusRef = useAutoFocus(isOpen);
  const mergedRef = mergeRefs([registerEmail.ref, autoFocusRef]);

  const onClose = (e: MouseEvent) => {
    resetFormValues();
    onRequestClose && onRequestClose(e);
  };

  const title = (
    <Title>
      <TitleIcon size={24} component={InviteIcon} />
      <H2>Invite Member</H2>
    </Title>
  );

  const description = (
    <P mb={12}>
      Invited team members have access to all the functionality of your account
      except profile settings
    </P>
  );

  const onSubmit = () => {
    handleSubmit((data) => {
      inviteAccountMember(data);
    })();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onRequestClose={onClose}
      withClose
      padding={isSmallMobile ? [20, 20] : [32, 32]}
      maxWidth={isSmallMobile ? 320 : 460}
      {...forwardingProps}
    >
      <>{description}</>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
          if (!isValid) return;
          onClose(e as MouseEvent<HTMLFormElement>);
        }}
        noValidate
      >
        <Row>
          <Input
            {...registerEmail}
            error={errors?.email?.message}
            ref={mergedRef}
            type="email"
            autoComplete="email"
            spellCheck={false}
            placeholder="example@gmail.com"
            fullWidth
          />
          <Button
            center
            disableShrink
            variation="accentPrimary"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
              if (!isValid) return;
              onClose(e);
            }}
          >
            {isSmallMobile ? 'Invite' : 'Send Invite'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

const Title = styled.div`
  display: flex;
`;

const TitleIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.accent};
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;
