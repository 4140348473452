import {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEvent,
  ReactNode,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
  Placement
} from '@floating-ui/react';
import styled from 'styled-components';
import { Control } from '../control';
import { ControlProps, ControlSize } from '../control-types';
import { ControlInput } from '../control-layout';
import { createDropdownSlideInAnimation } from '../../popovers/create-dropdown-slide-in-animation';
import { useFocused } from '../use-focused';
import { SearchInput } from '../search-input';
import { customScrollbarMixin } from '../../custom-scrollbar-mixin';
import { MOBILE_WIDTH } from '../../../breakpoints';
import { useMatchMediaQuery } from '../../../utils';
import { SelectIndicator } from './select-indicator';
import { SelectChangeEvent } from './select-popover-legacy';
import { GroupedSelectOptionsList } from './components';
import { getFilteredSelectOptions, getSelectOptionsSuggestions } from './utils';
import { SelectOption } from './select-option';

const OFFSET = 4;

export type SelectPopoverProps = Omit<
  SelectHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> &
  ControlProps<{
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    placeholder?: ReactNode;
    options: SelectOption[];
    withSearch?: boolean;
    maxHeight?: number;
    placement?: Placement;
  }>;

export function SelectPopover({
  options: initialOptions,
  placeholder,
  large,
  fullWidth,
  maxWidth,
  label,
  error,
  captionTop,
  captionBottom,
  onFocus,
  onBlur,
  maxHeight,
  onChange,
  value,
  withSearch,
  className,
  placement = 'bottom',
  ...forwardingProps
}: SelectPopoverProps) {
  const [isOpen, setOpen] = useState(false);
  const [items, setItems] = useState(initialOptions);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [showNotFound, setShowNotFound] = useState(false);
  const [showFullList, setShowFullList] = useState(false);

  const [focused, focusHandlers] = useFocused(onFocus, onBlur);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const isMobileQuery = useMatchMediaQuery({ maxWidth: MOBILE_WIDTH });
  const isDesktop = !isMobileQuery;
  const needMobileFocusTrapHack = withSearch && isMobileQuery;

  const handleOpenChange = (open: boolean) => {
    setOpen(open);

    if (open && needMobileFocusTrapHack) {
      refs.domReference.current?.focus();
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 250);
      return;
    }

    if (open && withSearch) {
      searchInputRef.current?.focus();
      return;
    }

    if (open) {
      return;
    }

    refs.domReference.current?.blur();
    setShowFullList(true);
    setShowNotFound(false);
    setInputValue('');
    setItems(initialOptions);
  };

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    open: isOpen,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(OFFSET),
      flip({ padding: 10 }),
      size({
        apply: ({ rects, elements, availableHeight }) => {
          const finalMaxHeight =
            maxHeight && maxHeight < availableHeight
              ? maxHeight
              : availableHeight;

          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: `${finalMaxHeight}px`
          });
        },
        padding: 10
      })
    ]
  });

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown'
  });
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
    virtual: true,
    focusItemOnOpen: !withSearch
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav]
  );

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    context,
    createDropdownSlideInAnimation()
  );

  useEffect(() => {
    const initialSelectedItem = initialOptions.find(
      (option) => option.value === value
    );
    if (initialSelectedItem) {
      setSelectedItem(initialSelectedItem);
    }
  }, [value]);

  const handleItemSelected = (item: SelectOption, index: number) => {
    setSelectedIndex(index);
    setSelectedItem(item);
    setActiveIndex(null);
    handleOpenChange(false);
    onChange({
      target: { value: item.value }
    } as ChangeEvent<HTMLSelectElement>);
  };

  const handleSearchReset = () => {
    setShowFullList(true);
    setShowNotFound(false);
    setInputValue('');
    setItems(initialOptions);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const filteredItems = getFilteredSelectOptions({
      items: initialOptions,
      filter: value
    });

    const suggestions = getSelectOptionsSuggestions(initialOptions);
    const finalItems = filteredItems.length > 0 ? filteredItems : suggestions;
    setItems(finalItems);

    setInputValue(value);
    setShowNotFound(filteredItems.length === 0);
    setShowFullList(false);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
      handleItemSelected(items[activeIndex], activeIndex);
    }
  };

  const controlSize: ControlSize = large ? 'large' : 'medium';
  const fontSize = { large: 16, medium: 14 }[controlSize];
  const height = { large: 48, medium: 36 }[controlSize];

  const handleInputClick = () => {
    handleOpenChange(!isOpen);
  };

  return (
    <>
      <Control
        fontSize={fontSize}
        height={height}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        focused={focused || isOpen}
        label={label}
        error={error}
        captionTop={captionTop}
        captionBottom={captionBottom}
        className={className}
      >
        {/**
         *  <readOnly: isMounted>
         *  Problem: input field hiding behind the keyboard on small mobile devices (<=5.0 inch),
         *  Main idea is to focus on anchor element and after mount popover focus on search field inside
         *  This trick force safari to keep anchor element in viewport
         */}
        <ControlInput
          {...getReferenceProps({
            tabIndex: 0,
            ref: refs.setReference,
            value: selectedItem?.label || '',
            onChange: () => undefined,
            onClick: handleInputClick,
            onKeyDown: handleInputKeyDown,
            placeholder: placeholder ? `${placeholder}…` : undefined,
            'aria-autocomplete': 'none',
            autoComplete: 'false',
            readOnly: needMobileFocusTrapHack ? isMounted : true
          })}
          {...focusHandlers}
          {...forwardingProps}
        />

        <StyledSelectIndicator _opened={isMounted} />
      </Control>

      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            visuallyHiddenDismiss
            initialFocus={isDesktop && withSearch ? searchInputRef : -1}
            returnFocus={isDesktop}
          >
            <Content
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  ...transitionStyles
                }
              })}
            >
              {withSearch && (
                <SearchContainer>
                  <SearchInput
                    ref={searchInputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onReset={handleSearchReset}
                  />
                </SearchContainer>
              )}

              <OptionsList>
                {isOpen && showNotFound && (
                  <GroupSectionListItem>
                    <Empty aria-disabled="true">No results found</Empty>
                    {items.length > 0 && <Separator />}
                  </GroupSectionListItem>
                )}

                <GroupedSelectOptionsList
                  items={items}
                  withGroups={showFullList || !inputValue.trim()}
                  selectedItem={selectedItem}
                  activeIndex={activeIndex}
                  onSelectItem={handleItemSelected}
                  floatingUIItemProps={getItemProps}
                  itemsListRef={listRef}
                />
              </OptionsList>
            </Content>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}

const Content = styled.div`
  margin: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 0;
  overflow-y: auto;
  outline: none;
  z-index: ${({ theme }) => theme.zIndex.contextMenuDropdown};

  ${customScrollbarMixin};
`;

const StyledSelectIndicator = styled(SelectIndicator)`
  position: absolute;
  right: 0;
  pointer-events: none;
`;

const SearchContainer = styled.div`
  padding: 8px 16px 16px;
`;

const GroupSectionListItem = styled.li`
  list-style-type: none;
`;

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.gray50};
  padding: 0 16px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.div.attrs({
  role: 'separator',
  'aria-disabled': true
})`
  height: 1px;
  margin: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const OptionsList = styled.ul`
  padding: 0;
  margin: 0;
`;
