import urlJoin from 'url-join';

export function createCommunityAuthLink(communityURL: string) {
  return (path: string) => {
    const ssoURL = urlJoin(communityURL, 'session/sso');
    const urlObject = new URL(ssoURL);
    urlObject.searchParams.append('return_path', path);
    return urlObject.toString();
  };
}
