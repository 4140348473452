import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Check16 } from '@elfsight/icons';
import {
  P,
  H2,
  Icon,
  Chip,
  Link,
  Divider,
  MOBILE,
  useMatchMediaQuery
} from '@elfsight-universe/ui-common';
import { useAppFeaturesQuery } from '@api';
import UnderliningIcon from '@icons/underlining.svg';
import { useUpgradeContext } from '@modules/upgrade/upgrade-context';

const ONE_COLUMN_BREAKPOINT = 570;
const SHRINK_COLUMN_FEATURES_COUNT = 6;

export type PricingFeaturesSingleAppProps = {
  isEnterprise: boolean;
};

export function PricingFeaturesSingleApp({
  isEnterprise
}: PricingFeaturesSingleAppProps) {
  const isOneColumnQuery = useMatchMediaQuery({
    maxWidth: ONE_COLUMN_BREAKPOINT
  });
  const { data: upgradeContextData } = useUpgradeContext();
  const { data } = useAppFeaturesQuery(upgradeContextData?.app?.alias);

  const appFeatures = data?.payload || [];

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isOneColumnQuery) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  }, [isOneColumnQuery]);

  const finalFeatures = isExpanded
    ? appFeatures
    : appFeatures.slice(0, SHRINK_COLUMN_FEATURES_COUNT);

  const onExpandButtonClick = () => {
    setIsExpanded((prevState) => !prevState);
  };

  if (!appFeatures.length) {
    return null;
  }

  return (
    <Container>
      <H2 mb={20}>
        All{' '}
        <Underlining>
          paid plans
          <StyledUnderliningIcon />
        </Underlining>{' '}
        include:
      </H2>

      <ItemList>
        {finalFeatures.map(({ pid, value, showSoonBadge }) => (
          <Item key={pid}>
            <CheckIcon size={16} component={Check16} fill="success" />
            <P>
              {value}
              {showSoonBadge && <SoonBadge color="gray90">soon</SoonBadge>}
            </P>
          </Item>
        ))}
      </ItemList>

      {isOneColumnQuery && (
        <ExpandButton
          forwardedAs="button"
          color="accent"
          onClick={onExpandButtonClick}
        >
          {isExpanded ? 'Hide features' : 'See all features'}
        </ExpandButton>
      )}
      {isEnterprise && <StyledDivider _marginY={0} />}
    </Container>
  );
}

const Container = styled.div`
  padding-top: 48px;

  ${MOBILE} {
    padding-top: 32px;
  }
`;

const StyledUnderliningIcon = styled(UnderliningIcon)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -6px;

  color: ${({ theme }) => theme.colors.success};
  stroke-opacity: 0.5;
`;

const Underlining = styled.span`
  position: relative;
`;

const ItemList = styled.ul`
  padding: 0;
  margin: 0;

  columns: 250px auto;
  column-gap: 30px;

  @media (max-width: ${ONE_COLUMN_BREAKPOINT}px) {
    column-count: 1;
  }
`;

const Item = styled.li`
  list-style: none;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CheckIcon = styled(Icon)`
  margin-right: 8px;
`;

const SoonBadge = styled(Chip)`
  align-self: center;
  margin-left: 8px;
`;

const ExpandButton = styled(Link)`
  margin-top: 10px;
  padding: 0;
  border: none;
  background-color: transparent;
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px;

  ${MOBILE} {
    margin-top: 32px;
  }
`;
